import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import Hero from "../components/hero";

const WTEPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "What-to-Expect-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img1: wpMediaItem(title: { eq: "Group-Shot" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What To Expect",
				item: {
					url: `${siteUrl}/what-to-expect`,
					id: `${siteUrl}/what-to-expect`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="What To Expect | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-to-expect`,
					title: "What To Expect | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > What to expect"
						title="What to expect"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="py-5 pt-md-0 pb-lg-7">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-5">
									What to expect when you work with us
								</h2>

								<p>
									Having contacted us, you will be sent an initial intake form,
									and some assessment forms to complete and return.{" "}
								</p>
								<p>
									Initial assessments are carried out via Zoom or Microsoft
									Teams – your therapist will send you a link before your
									appointment.
								</p>
								<p>
									Facial therapy requires an in-depth assessment of facial
									movement and muscle length. We will educate you on what is
									happening and why, so that you can increase your understanding
									of your condition and learn how to manage it most effectively.
								</p>
								<p>
									Please allow up to 2 hours for your initial session as this
									gives your therapist sufficient time to do a thorough
									assessment, discuss and teach you your home management plan.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7 bg-light-grey">
					<Container>
						<Row className="h-100">
							<Col
								className="d-flex align-items-center"
								style={{ minHeight: "100%" }}
								lg={5}
							>
								<h2 className="text-dark-bg mb-5 mb-lg-0 ">
									What do I need to do to prepare for my appointment?
								</h2>
							</Col>
							<Col
								style={{ minHeight: "100%" }}
								lg={2}
								className="text-center d-none d-lg-block"
							>
								{" "}
								<div
									style={{ width: "2px", height: "40%" }}
									className="bg-grey mx-auto"
								></div>
								<div
									className="d-flex align-items-center justify-content-center"
									style={{ height: "20%" }}
								>
									<IoIosArrowForward className="fs-2 text-dark-bg" />
								</div>
								<div
									style={{ width: "2px", height: "40%" }}
									className="bg-grey mx-auto"
								></div>
							</Col>
							<Col lg={5}>
								<div className="pb-4">
									<BsFillCheckCircleFill className="text-secondary fs-2 mb-2" />
									<p className="text-dark-bg ubuntu-medium">
										Please ensure that you are in a well-lit room with your hair
										away from your face
									</p>
								</div>
								<div className="pb-4">
									<BsFillCheckCircleFill className="text-secondary fs-2 mb-2" />
									<p className="text-dark-bg ubuntu-medium">
										You will be asked to place your hands inside your mouth to
										assess your muscle length, so please clean your hands, or
										have some gloves available.
									</p>
								</div>
								<div className="pb-4">
									<BsFillCheckCircleFill className="text-secondary fs-2 mb-2" />
									<p className="text-dark-bg ubuntu-medium">
										It can be helpful for us to see a photograph of you prior to
										your facial palsy, so please feel free to share that with us
										if you wish.
									</p>
								</div>
								<div className="">
									<BsFillCheckCircleFill className="text-secondary fs-2 mb-2" />
									<p className="text-dark-bg ubuntu-medium">
										Have a pen and paper available to take notes
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<GatsbyImage
						className="w-100"
						image={data.img1.localFile.childImageSharp.gatsbyImageData}
						alt={data.img1.altText}
					/>
				</section>
				{/* <section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="text-dark-bg text-center mb-5 mb-lg-7">FAQ’S</h2>
							</Col>
						</Row>
						<Row className="g-5 g-lg-7">
							<Col lg={6} xl={4}>
								<div
									style={{
										borderRadius: "24px",
										boxShadow: "0px 3px 23px #00000029",
									}}
									className="p-4"
								>
									<div className="text-center mb-4">
										<StaticImage
											style={{ maxWidth: "200px" }}
											src="../images/SVG - Icon Q&A.svg"
											quality={100}
											className=" "
											placeholder="traced_svg"
										/>
									</div>
									<h3 className="text-dark-bg  fs-5 mb-4">
										Lorem ipsum dolor sit amet ?
									</h3>
									<p>
										Consetetur sadipscing elitr, sed diam nonumy eirmod tempor
										invidunt ut labore et dolore magna aliquyam erat, sed diam
										voluptua. At vero eos et accusam et justo duo dolores et ea
										rebum.
									</p>
								</div>
							</Col>
							<Col lg={6} xl={4}>
								<div
									style={{
										borderRadius: "24px",
										boxShadow: "0px 3px 23px #00000029",
									}}
									className="p-4"
								>
									<div className="text-center mb-4">
										<StaticImage
											style={{ maxWidth: "200px" }}
											src="../images/SVG - Icon Q&A.svg"
											quality={100}
											className=" "
											placeholder="traced_svg"
										/>
									</div>
									<h3 className="text-dark-bg  fs-5 mb-4">
										Lorem ipsum dolor sit amet ?
									</h3>
									<p>
										Consetetur sadipscing elitr, sed diam nonumy eirmod tempor
										invidunt ut labore et dolore magna aliquyam erat, sed diam
										voluptua. At vero eos et accusam et justo duo dolores et ea
										rebum.
									</p>
								</div>
							</Col>
							<Col lg={6} xl={4}>
								<div
									style={{
										borderRadius: "24px",
										boxShadow: "0px 3px 23px #00000029",
									}}
									className="p-4"
								>
									<div className="text-center mb-4">
										<StaticImage
											style={{ maxWidth: "200px" }}
											src="../images/SVG - Icon Q&A.svg"
											quality={100}
											className=" "
											placeholder="traced_svg"
										/>
									</div>
									<h3 className="text-dark-bg  fs-5 mb-4">
										Lorem ipsum dolor sit amet ?
									</h3>
									<p>
										Consetetur sadipscing elitr, sed diam nonumy eirmod tempor
										invidunt ut labore et dolore magna aliquyam erat, sed diam
										voluptua. At vero eos et accusam et justo duo dolores et ea
										rebum.
									</p>
								</div>
							</Col>
							<Col lg={6} xl={4}>
								<div
									style={{
										borderRadius: "24px",
										boxShadow: "0px 3px 23px #00000029",
									}}
									className="p-4"
								>
									<div className="text-center mb-4">
										<StaticImage
											style={{ maxWidth: "200px" }}
											src="../images/SVG - Icon Q&A.svg"
											quality={100}
											className=" "
											placeholder="traced_svg"
										/>
									</div>
									<h3 className="text-dark-bg  fs-5 mb-4">
										Lorem ipsum dolor sit amet ?
									</h3>
									<p>
										Consetetur sadipscing elitr, sed diam nonumy eirmod tempor
										invidunt ut labore et dolore magna aliquyam erat, sed diam
										voluptua. At vero eos et accusam et justo duo dolores et ea
										rebum.
									</p>
								</div>
							</Col>
							<Col lg={6} xl={4}>
								<div
									style={{
										borderRadius: "24px",
										boxShadow: "0px 3px 23px #00000029",
									}}
									className="p-4"
								>
									<div className="text-center mb-4">
										<StaticImage
											style={{ maxWidth: "200px" }}
											src="../images/SVG - Icon Q&A.svg"
											quality={100}
											className=" "
											placeholder="traced_svg"
										/>
									</div>
									<h3 className="text-dark-bg  fs-5 mb-4">
										Lorem ipsum dolor sit amet ?
									</h3>
									<p>
										Consetetur sadipscing elitr, sed diam nonumy eirmod tempor
										invidunt ut labore et dolore magna aliquyam erat, sed diam
										voluptua. At vero eos et accusam et justo duo dolores et ea
										rebum.
									</p>
								</div>
							</Col>
							<Col lg={6} xl={4}>
								<div
									style={{
										borderRadius: "24px",
										boxShadow: "0px 3px 23px #00000029",
									}}
									className="p-4"
								>
									<div className="text-center mb-4">
										<StaticImage
											style={{ maxWidth: "200px" }}
											src="../images/SVG - Icon Q&A.svg"
											quality={100}
											className=" "
											placeholder="traced_svg"
										/>
									</div>
									<h3 className="text-dark-bg  fs-5 mb-4">
										Lorem ipsum dolor sit amet ?
									</h3>
									<p>
										Consetetur sadipscing elitr, sed diam nonumy eirmod tempor
										invidunt ut labore et dolore magna aliquyam erat, sed diam
										voluptua. At vero eos et accusam et justo duo dolores et ea
										rebum.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-5">
									What will happen during my first appointment?
								</h2>
								<p>
									- At the beginning of your assessment, we will clarify your
									medical history and ask you to explain how your facial palsy
									started, how long it has been going on, what therapy you have
									had already (if any) and what your main concerns and
									difficulties are. Your consent to documenting this information
									and proceeding with your assessment will be checked by your
									therapist.
								</p>
								<p>
									- We will then assess your facial movement and take
									screenshots or photographs as a baseline assessment. You will
									be asked to consent to your photographs being taken as these
									are very useful when monitoring your progress. These are
									stored as part of your medical record, in accordance with our
									GDPR policy.
								</p>
								<p>
									- Your therapist will then guide you through a systematic
									assessment of your facial muscle length and function. They may
									teach you appropriate muscle release techniques and stretches.
									They may also cover relaxation techniques and movement
									retraining work with you, depending on your presentation.
								</p>
								<p>
									- We would encourage you to take notes during your session as
									information written in your own words, can be easiest for you
									to remember. Your therapist will also email you a bespoke home
									management plan for you to adhere to in between sessions
								</p>
								<p>
									- A lot of information is covered during your initial 2-hour
									session, and your home management plan is key to your progress
									over time.{" "}
								</p>
								<p>
									- We do not need to see you that frequently – usually monthly
									initially, and then less frequently going forwards. We can of
									course see you more frequently if you require more support.
								</p>
								<p>
									- Your therapist will help you to set short and long term
									goals for your rehabilitation.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 bg-light-grey py-lg-7">
					<Container>
						<Row>
							<Col className="">
								<p className="ubuntu-bold text-center">
									We have established links with expert plastic surgeons and
									corneoplastic surgeons who specialise in facial palsy and can
									refer you onto see them as required. We can also link you in
									with specialist psychological therapists if needed, or
									specialist NHS services if you are based in the UK.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="">
								<h2 className="text-dark-bg text-center mb-5">
									What will my therapist expect of me throughout my facial
									rehabilitation?
								</h2>
								<p>
									- Your facial therapy will be done daily, by you, at home.
									Your therapist will outline how frequently your stretches,
									soft tissue mobilisation, movement retraining work, or facial
									relaxation sessions should be completed.
								</p>
								<p>
									- We will ensure that your programme is tailored to your
									specific requirements and take into account your current work
									– life balance / personal commitments.
								</p>
								<p>
									- You will get the best results if you follow the advice given
									by your therapist. - Please let us know if any aspect of your
									home programme is too challenging, causes you discomfort, or
									is difficult to follow, and we will adapt that to the best of
									our ability.
								</p>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		</>
	);
};

export default WTEPage;
